import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

/**
 * Represents a component that is displayed when there is no data available.
 */
@Component({
  selector: 'app-no-data',
  standalone: true,
  imports: [SvgIconComponent, NgClass],
  template: `
    <div class="no-data">
      <div class="no-data-icon">
        @if (icon()) {
          <svg-icon src="assets/icons/{{ icon() }}"></svg-icon>
        } @else {
          <i class="pi" [ngClass]="fontIcon()"></i>
        }
      </div>
      <div class="no-data-title">{{ title() }}</div>
      @if (description()) {
        <div class="no-data-description">{{ description() }}</div>
      }

      <div class="no-data-action">
        <ng-content select="[action]"></ng-content>
      </div>
    </div>
  `,
  styleUrl: './no-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {
  /**
   * The title to be displayed in the component.
   */
  title = input.required<string>();

  /**
   * The icon to be displayed in the component.
   */
  icon = input<string>();
  /**
   * The font icon for the component.
   */
  fontIcon = input<string>('pi-times-circle');

  /**
   * The description to be displayed in the component.
   */
  description = input<string>();
}
